import Image from '../components/Image'
import Legends from '../components/Legends'
import { toppingTokens } from '../language/ItemTokens'
import { formatText } from '../Utils'

export default function Toppings({data}) {
  const lang = data.lang
  const toppings = data.toppings
  const setToppings = data.setToppings
  // Different prices for the toppings
  const prices = [ 50, 60, 80 ]

  const isSelected = ((id) => (toppings.includes(id)))
  
  function select(e) {
    const id = e.currentTarget.id
    let t;
    if (isSelected(id)) {
      t = toppings.filter((item) => (item !== id))
    }
    else {
      toppings.push(id)
      t = toppings.slice()
    }
    setToppings(t)
  }

  const getImg = ((id) => {
    switch (id) {
      case 'pc':
      case 'gj':
      case 'tp':
      case 'ay':
        return id
      default:
        return 'rj'
    }
  })

  // Topping item with the picture and label
  const Topping = (({item}) => {
    const selected = isSelected(item.id)
    return (
      <div className='topping-box' id={item.id} onClick={select}>
        <div className='topping-img-container'>
          <Image 
            folder='toppings'
            name = {getImg(item.id)}
            cls={'topping-img p' + item.p + (selected ? ' selected' : '')}
          />
          <div className={'topping-img-plus p' + item.p}>{selected ? '+' : ''}</div>
        </div>
        <div>{formatText(item.n, lang)}</div>
      </div>
    )
  })

  // use item.id instead of 'ilt'
  return (<div style={{display: 'inline-block'}}>
    <Legends prices={prices} token='selToppings' lang={lang} />
    <div className='topping-grid'>
      <div className='topping-box'>
        <Image cls='topping-drink' folder='drinks' name='ilt' />
      </div>
      { toppingTokens.list.data.map((t) => (
          <Topping key={t.id} item={t} />
      ))}
    </div>
  </div>)
}
