// Constructor of an object with the findItem() method
function ItemList(data) {
  this.data = data;
  this.findItem = ((id) => this.data.find((item) => item.id === id));
}

const juice = {
  n: [ 'Juice/Milk', '果汁/牛奶' ],
  id: 'jm',
  bk: 'hsl(40, 100%, 50%)',
  bki: 'hsl(40, 100%, 75%)',
  lightBk: true,
  list: new ItemList([
  { id: 'lh', n: [ 'Lemon Honey',       '蜂蜜檸檬汁' ], p: 400 },
  { id: 'kl', n: [ 'Kumquat Lemon',     '金桔檸檬汁' ], p: 400 }, // 金橘
  { id: 'ha', n: [ 'Honey Aloe',        '蜂蜜蘆薈露' ], p: 425 },
  { id: 'la', n: [ 'Lemon Ai-Yu',       '檸檬愛玉' ],   p: 425 },
  { id: 'jh', n: [ 'Grass Jelly Honey', '仙草蜜' ],     p: 425 },
  { id: 'jm', n: [ 'Grass Jelly Milk',  '仙草奶凍' ],   p: 425 },
  { id: 'gb', n: [ 'Green Bean Milk',   '綠豆牛奶' ],   p: 425 },
  { id: 'rb', n: [ 'Red Bean Milk',     '紅豆牛奶' ],   p: 425 },
  ])
}

const blackTea = {
  n: [ 'Black Tea', '紅茶/烏龍茶' ],
  id: 'bt',
  bk: 'hsl(328, 100%, 50%)',
  bki: 'hsl(328, 100%, 75%)',
  list: new ItemList([
  { id: 'lm', n: [ 'Lemon Black Tea',       '蜂蜜紅茶' ],     p: 425 },
  { id: 'bt', n: [ 'Black Tea',             '紅茶' ],         p: 400 },
  { id: 'as', n: [ 'Assam Black Tea',       '阿薩姆紅茶' ],   p: 400 },
  { id: 'os', n: [ 'Osmanthus Black Tea',   '桂花紅茶' ],     p: 400 },
  { id: 'cl', n: [ 'Ceylon Black Tea',      '錫蘭紅茶' ],     p: 400 },
  { id: 'ol', n: [ 'Oolong Tea',            '高山烏龍茶' ],   p: 400 },
  { id: 'po', n: [ 'Peach Oolong',          '白桃烏龍茶' ],   p: 400 },
  { id: 'oo', n: [ 'Osmanthus Oolong',      '桂花烏龍茶' ],   p: 400 },
  { id: 'gr', n: [ 'Grape Oolong Tea',      '白葡萄烏龍茶' ], p: 400 },
  { id: 'dr', n: [ 'Dark Roast Oolong Tea', '碳燒烏龍茶' ],   p: 400 },
  ])
}

const greenTea = {
  n: [ 'Green Tea', '綠茶/日本茶' ],
  id: 'gt',
  bk: 'hsl(128, 82%, 50%)',
  bki: 'hsl(128, 82%, 75%)',
  lightBk: true,
  list: new ItemList([
  { id: 'jm', n: [ 'Jasmine Green Tea',       '茉香綠茶' ],   p: 400 },
  { id: 'ho', n: [ 'Honey Green Tea',         '蜂蜜綠茶' ],   p: 425 },
  { id: 'gf', n: [ 'Grapefruit Green Tea',    '葡萄柚綠茶' ], p: 425 },
  { id: 'kq', n: [ 'Kumquat Green Tea',       '金桔綠茶'],    p: 425 },
  { id: 'mg', n: [ 'Mango Green Tea',         '芒果綠茶' ],   p: 425 },
  { id: 'sb', n: [ 'Strawberry Green Tea',    '草莓綠茶' ],   p: 425 },
  { id: 'bb', n: [ 'Blueberry Green Tea',     '藍莓綠茶' ],   p: 425 },
  { id: 'rp', n: [ 'Raspberry Green Tea',     '覆盆子綠茶' ], p: 425 },
  { id: 'pf', n: [ 'Passion Fruit Green Tea', '百香果綠茶' ], p: 425 },
  { id: 'pe', n: [ 'Peach Green Tea',         '水蜜桃綠茶' ], p: 425 },
  { id: 'lc', n: [ 'Lychee Green Tea',        '荔枝綠茶' ],   p: 425 },
  { id: 'hd', n: [ 'Honeydew Green Tea',      '哈密​​瓜綠茶' ], p: 425 },
  { id: 'yg', n: [ 'Yogurt Green Tea',        '優格綠茶' ],   p: 425 },
  { id: 'hj', n: [ 'Hojicha',                 '日式烤茶' ],   p: 400, x: true },
  { id: 'gm', n: [ 'Genmaicha',               '玄米茶' ],     p: 400, x: true },
  ])
}

const milkTea = {
  n: [ 'Milk Tea', '奶茶' ],
  id: 'mt',
  bk: 'hsl(27, 80%, 50%)',
  bki: 'hsl(27, 80%, 75%)',
  list: new ItemList([
  { id: 'th', n: [ 'Thai Tea',              '泰式奶茶' ],     p: 425, noSweet: true },
  { id: 'as', n: [ 'Assam Milk Tea',        '阿薩姆奶茶' ],   p: 425 },
  { id: 'ol', n: [ 'Oolong Milk Tea',       '烏龍奶茶' ],     p: 425 },
  { id: 'cl', n: [ 'Ceylon Milk Tea',       '錫蘭奶茶' ],     p: 425 },
  { id: 'eg', n: [ 'Earl Grey Milk Tea',    '伯爵奶茶' ],     p: 425 },
  { id: 'bu', n: [ 'Black Sugar Milk Tea',  '黑糖奶茶' ],     p: 425 },
  { id: 'co', n: [ 'Coffee Milk Tea',       '咖啡奶茶' ],     p: 425 },
  { id: 'ho', n: [ 'Honey Milk Tea',        '蜂蜜奶茶' ],     p: 425 },
  { id: 'jm', n: [ 'Jasmine Milk Tea',      '茉香奶茶' ],     p: 425 },
  { id: 'gj', n: [ 'Grass Jelly Milk Tea',  '仙草奶茶' ],     p: 425 },
  { id: 'os', n: [ 'Osmanthus Milk Tea',    '桂花奶茶' ],     p: 425 },
  { id: 'hj', n: [ 'Hojicha Milk Tea',      '日式烤奶茶' ],   p: 425, x: true },
  { id: 'gm', n: [ 'Genmaicha Milk Tea',    '玄米奶茶' ],     p: 425, x: true },
  ])
}

const milkTea2 = {
  n: [ 'Flavored Milk&nbsp;Tea', '鮮味奶茶' ],
  id: 'fm',
  bk: 'hsl(20, 50%, 50%)',
  bki: 'hsl(20, 50%, 75%)',
  list: new ItemList([
    { id: 'ta', n: [ 'Taro Milk Tea',         '芋香奶茶' ],     p: 425 },
    { id: 'cn', n: [ 'Coconut Milk Tea',      '椰香奶茶' ],     p: 425 },
    { id: 'va', n: [ 'Vanilla Milk Tea',      '香草奶茶' ],     p: 425 },  
    { id: 'hd', n: [ 'Honeydew Milk Tea',     '哈密​​瓜奶茶' ],   p: 425 },
    { id: 'gg', n: [ 'Ginger Milk Tea',       '薑母奶茶' ],     p: 425 },
    { id: 'lg', n: [ 'Longan Milk Tea',       '龍眼奶茶' ],     p: 425 },
    { id: 'pm', n: [ 'Peppermint Milk Tea',   '薄荷奶茶' ],     p: 425 },
    { id: 'mc', n: [ 'Matcha Milk Tea',       '抹茶奶茶' ],     p: 425 },
    { id: 'ch', n: [ 'Chocolate Milk Tea',    '巧克力奶茶' ],   p: 425 },
    { id: 'bs', n: [ 'Black Sesame Milk Tea', '黑芝麻奶茶' ],   p: 425 },  
    { id: 'po', n: [ 'Peach Oolong Milk Tea', '白桃烏龍奶茶' ], p: 425 },
    { id: 'pf', n: [ 'Passion Fruit Milk Tea','百香果奶茶' ],   p: 425 },
  ])
}

const latte = {
  n: [ 'Tea Latte', '茶拿鐵' ],
  id: 'la',
  bk: 'hsl(320, 50%, 50%)',
  bki: 'hsl(320, 50%, 75%)',
  list: new ItemList([
  { id: 'as', n: [ 'Assam Latte',        '阿薩姆鮮茶' ],     p: 450 },
  { id: 'cl', n: [ 'Ceylon Latte',       '錫蘭鮮奶茶' ],     p: 450 },
  { id: 'eg', n: [ 'Earl Grey Latte',    '伯爵鮮奶茶' ],     p: 450 },
  { id: 'gm', n: [ 'Genmaicha Latte',    '玄米鮮奶茶' ],     p: 450 },
  { id: 'hj', n: [ 'Hojicha Latte',      '日式鮮烤茶' ],     p: 450 },
  { id: 'ol', n: [ 'Oolong Latte',       '烏龍鮮奶茶' ],     p: 450 },
  { id: 'po', n: [ 'Peach Oolong Latte', '白桃烏龍鮮奶茶' ], p: 450 },
  { id: 'os', n: [ 'Osmanthus Latte',    '桂花鮮奶茶' ],     p: 450 },
  ])
}

const smoothie = {
  n: [ 'Smoothie', '雪泡' ],
  id: 'sm',
  bk: 'hsl(0, 0%, 100%)',
  bki: 'hsl(0, 0%, 90%)',
  noSweet: true,
  noTemp: true,
  lightBk: true,
  list: new ItemList([
  { id: 'sb', n: [ 'Strawberry Snow',    '草莓雪泡' ],   p: 425 },
  { id: 'bb', n: [ 'Blueberry Snow',     '藍莓雪泡' ],   p: 425 },
  { id: 'rp', n: [ 'Raspberry Snow',     '覆盆子雪泡' ], p: 425 },
  { id: 'mg', n: [ 'Mango Snow',         '芒果雪泡' ],   p: 425 },
  { id: 'pe', n: [ 'Peach Snow',         '水蜜桃雪泡' ], p: 425 },
  { id: 'pf', n: [ 'Passion Fruit Snow', '百香果雪泡' ], p: 425 },
  { id: 'lc', n: [ 'Lychee Snow',        '荔枝雪泡' ],   p: 425 },
  { id: 'ta', n: [ 'Taro Snow',          '芋頭雪泡' ],   p: 425 },
  { id: 'yg', n: [ 'Yogurt Snow',        '優格雪泡' ],   p: 425 },
  { id: 'lm', n: [ 'Lemon Snow',         '檸檬雪泡' ],   p: 425 },
  { id: 'cn', n: [ 'Coconut Snow',       '椰子雪泡' ],   p: 425 },
  { id: 'hd', n: [ 'Hoenydew Snow',      '哈密​​瓜雪泡' ], p: 425 },
  { id: 'mc', n: [ 'Matcha Snow',        '抹茶雪泡' ],   p: 425 },
  { id: 'bs', n: [ 'Black Sesame Snow',  '黑芝麻雪泡' ], p: 425 },
  ])
}

const slushie = {
  n: [ 'Sluthie', '沙冰' ],
  id: 'sl',
  bk: 'hsl(180, 100%, 50%)',
  bki: 'hsl(180, 100%, 75%)',
  noSweet: true,
  noTemp: true,
  lightBk: true,
  list: new ItemList([
  { id: 'sb', n: [ 'Strawberry Icy',    '草莓沙冰' ],   p: 425 },
  { id: 'bb', n: [ 'Blueberry Icy',     '藍莓沙冰' ],   p: 425 },
  { id: 'rp', n: [ 'Raspberry Icy',     '覆盆子沙冰' ], p: 425 },
  { id: 'mg', n: [ 'Mango Icy',         '芒果沙冰' ],   p: 425 },
  { id: 'pe', n: [ 'Peach Icy',         '水蜜桃沙冰' ], p: 425 },
  { id: 'pf', n: [ 'Passion Fruit Icy', '百香果沙冰' ], p: 425 },
  { id: 'lc', n: [ 'Lychee Icy',        '荔枝沙冰' ],   p: 425 },
  { id: 'ta', n: [ 'Taro Icy',          '芋頭沙冰' ],   p: 425 },
  { id: 'yg', n: [ 'Yogurt Icy',        '優格沙冰' ],   p: 425 },
  { id: 'lm', n: [ 'Lemon Icy',         '檸檬沙冰' ],   p: 425 },
  { id: 'gb', n: [ 'Green Bean Icy',    '綠豆沙冰' ],   p: 425 },
  { id: 'rb', n: [ 'Red Bean Icy',      '紅豆沙冰' ],   p: 425 },
  { id: 'rm', n: [ 'Red Bean Milk Icy', '紅豆牛奶冰' ], p: 425 },
  ])
}

export const drinks = [
  juice,
  blackTea,
  greenTea,
  milkTea,
  milkTea2,
  latte,
  smoothie,
  slushie,
]

export const toppingTokens = {
  n: [ 'Toppings', '配料' ],
  list: new ItemList([
  { id: 'tp', n: [ 'Tapioca',           '珍珠' ],           p: 50 },
  { id: 'gj', n: [ 'Grass Jelly',       '仙草' ],           p: 50 },
  { id: 'ay', n: [ 'Ai-Yu',             '愛玉' ],           p: 50 },
  { id: 'pd', n: [ 'Pudding',           '布丁' ],           p: 50 },
  { id: 'gb', n: [ 'Green Bean',        '綠豆' ],           p: 50 },
  { id: 'rb', n: [ 'Red Bean',          '紅豆' ],           p: 50 },
  { id: 'ag', n: [ 'Agar',              '寒天' ],           p: 80 },
  { id: 'al', n: [ 'Aloe',              '蘆薈' ],           p: 60 },
  { id: 'rj', n: [ 'Rainbow',           '彩虹椰果' ],       p: 60 },
  { id: 'cj', n: [ 'Coffee',            '咖啡椰果' ],       p: 60 },
  { id: 'lj', n: [ 'Lychee',            '荔枝椰果' ],       p: 60 },
  { id: 'ga', n: [ 'Greenapple',        '青蘋果椰果' ],     p: 60 },
  { id: 'pc', n: [ 'Pineapple Coconut', '鳳梨椰子椰果' ],   p: 60 },
  ])
}
