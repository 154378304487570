import { formatToken } from '../Utils'

export default function Legends({prices, token, lang}) {
  const formatPrice = ((p) => (p > 200 ? '$' + parseFloat(p / 100).toFixed(2) : p + '¢'))
  return (<div className='legend'>
    <span>{formatToken(token, lang) + ':'}</span>
    { prices.map((p) => (<div key={p}>
        <button type='button' className={'legend-box p' + p}></button> 
        <span> {formatPrice(p)}</span>
    </div>))}
  </div>)
}