import { useEffect } from 'react'
//
import Image from '../components/Image'
import { formatToken, setBodyColor } from '../Utils'

export default function Home({lang}) {
  const URL = 'http://maps.google.com/?q=4299 Rosewood Dr., Pleasanton, CA 94588'

  useEffect(() => {
    // Set background to the default color in case it has been changed
    setBodyColor()
  })

  function openMaps() { 
    window.open(URL, '_blank')
    // const newTab = document.createElement('a')
    // newTab.href = URL
    // newTab.target = '_blank'
    // newTab.click()
  }

//<button onClick={openMaps}>Maps</button>
//<a href={URL}>Google Maps</a>
//style={{fontWeight: 'normal'}}
//<div style={{fontWeight: 'normal'}}>{formatToken('location', lang)}</div>

  return (
    <div className='home'>
      <div>{formatToken('hours', lang)}</div>
      <Image name='ranch99' cls='home-image' />
      <p>{formatToken('location', lang)}</p>
      <div>4299 Rosewood Dr.</div>
      <div>Pleasanton, CA 94588</div>
      <div>(510) 623-1523</div>       
      <Image name='ranch99map' cls='home-map' click={openMaps}/>
    </div>
  );
}
