import { useEffect } from 'react'
//
import { addOrRemoveClass, formatToken } from '../Utils'
// css
import '../css/App.css'

const include = ((id, sweet) => parseInt(id) <= parseInt(sweet))

export default function Sweetness({data}) {
  const lang = data.lang
  const sweet = data.sweet
  const levels = [ '100', '75', '50', '25', '0' ]
  
  useEffect(() => {
    document.querySelectorAll('.group-button.sweet').forEach(btn => {
      addOrRemoveClass((btn.id === sweet), btn, 'selected')
    })
    document.querySelectorAll('.group-button.percent').forEach(btn => {
      if (btn.id !== '0') {
        addOrRemoveClass(include(btn.id, sweet), btn, 'included')
      }
      addOrRemoveClass((btn.id === sweet), btn, 'selected')
    })
  }, [sweet])

  function clicked(e) { data.setSweet(e.currentTarget.id) }

  const SweetButtons = () => (
    <div className='option-row'>
      { levels.map((l) => (
        <button type='button' key={l} id={l} onClick={clicked} 
         className={'group-button sweet' + (l === sweet ? ' selected' : '')} >
          {formatToken('sweet' + l, lang)}
        </button>
      ))}
    </div>
  )

  const PercentButtons = () => (
    <div className='option-row'>
      { levels.map((l) => (
        <button type='button' key={l} id={l} onClick={clicked} 
          className={'group-button percent'
          + (l === '100' ? ' left' : '')
          + (l === '25' ? ' right' : '')
          + (l === '0' ? ' zero' : '')
          + (l !== '0' && include(l, sweet) ? ' included' : '')
          + (l === sweet ? ' selected' : '')
          }>
            {l + '%'}
        </button>
      ))}
    </div>
  )

  return (
    <div style={{marginBottom: '10px'}}> 
      <SweetButtons />   
      <PercentButtons />
    </div>
  )
}
