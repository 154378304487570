import { drinks } from '../language/ItemTokens'
import { formatText } from '../Utils';

export function CategoryButton({showCats, index, clicked, lang}) {
  const cat = drinks[index]
  const dropChar = (showCats === undefined ? '' : ' ' + (showCats ? '▲' : '▼'))
  return (
    <button type='button' id={index}  
            className={'category-button' + (cat.lightBk ? ' lightBk' : '') + (dropChar ? ' top' : '')}
            style={{backgroundColor: cat.bk}} 
            onClick={clicked}>
      {formatText(cat.n, lang) + dropChar}
    </button>
  )
}

export default function CategoryBar({clicked, lang}) {
  return (
    <div className='category-bar'>
      {drinks.map((cat, i) => (
        <div key={cat.id}>
          <CategoryButton index={i} clicked={clicked} lang={lang} />
        </div>
      ))}
    </div>
  )
}
