import { useCallback, useEffect, useState } from 'react'
import { NavLink, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
//
import { CategoryButton } from './components/CatBar'
import Image from './components/Image'
import LangSel from './components/LangSel'
import { checkLang } from './language/Langs'
import { useLocalStorageValue  } from './Utils'
// Pages
import Home from './pages/Home'
import Menu from './pages/Menu'
import Item from './pages/Item'
import Cart from './pages/Cart'
// css
import './css/App.css'

export default function App() {
  // Get the language setting from the params of URL in the form of "path?lang=en"
  let langParam = new URLSearchParams(useLocation().search).get('lang')
  //console.log('langParam1 =', langParam)
  langParam = checkLang(langParam)
  //console.log('langParam2 =', langParam)
  // If it has been run before, use the previously saved language setting
  const [lang, setLang] = useLocalStorageValue(langParam, 'LANG')
  const changeLang = useCallback((newLang) => {
      setLang(newLang)
  }, [setLang])

    // Make sure it could only start at the Home or Menu page
  const [isFirstRun, setIsFirstRun] = useState(true)
  const navigate = useNavigate()
  const path = useLocation().pathname

  useEffect(() => {
    if (isFirstRun) {
      //console.log('navigate to menu!');
      setIsFirstRun(false)
      if (path !== '/home'  &&  path !== '/menu') {
        navigate('/menu')
      }
    }
  }, [isFirstRun, path, navigate])

  const [showCats, setShowCats] = useState(true)
  const [category, setCategory] = useState(0)
  const [sweet, setSweet] = useState('100')
  const [temp, setTemp] = useState('Iced')
  const [drink, setDrink] = useState({})
  const [toppings, setToppings] = useState([])
  const [cart, setCart] = useState([])
  const data = {
    lang,
    showCats, setShowCats,
    category, setCategory,
    sweet, setSweet,
    temp, setTemp,
    drink, setDrink,
    toppings, setToppings,
    cart, setCart,
  }

  // Just for debugging
  useEffect(() => {
    console.log('App cart = ', JSON.stringify(cart))
  }, [cart])

  const Header = () => (
    <div className='app-header'>
      <Image name='teaisland' />
    </div> 
  )

  const PageLink = (({id, char}) => (
      <NavLink to={id} 
        className={({ isActive }) => 'menu-button' + (isActive ? ' active' : '') }
      > 
        <span>{char}</span>
      </NavLink>
  ))

  const Pages = ({lang}) => (
    <div className='app-body'>
      <Routes>
        <Route exact path='/' element={<Home lang={lang}/>} />
        <Route path='/home' element={<Home lang={lang}/>} />
        <Route path='/menu' element={<Menu data={data}/>} />
        <Route path='/item' element={<Item data={data}/>} />
        <Route path='/cart' element={<Cart data={data}/>} />
        <Route path='*' element={<Menu lang={lang}/>} />
      </Routes>
    </div>
  )

  function toggleShowCats() {
    setShowCats(! showCats)
  }

  // The header is shown only on the Home page
  return (
    <div className='app-main'>
      { (path === '/home') && <Header /> }
      <div className='menu-bar' >
        <PageLink id='home' char='🏠'/>
        <PageLink id='menu' char='🥤'/>
        <PageLink id='cart' char='🛒'/>
        { (path === '/menu') && <CategoryButton showCats={showCats} index={category} clicked={toggleShowCats} lang={lang} /> }
        <LangSel changeLang = {changeLang} lang={lang} />
      </div>
      <Pages lang={lang}/>
    </div>
  )
}
