// A lot of work to remove the broken icon of the alt text When the image is not found
export function removeBrokenIcon(e) {
  const elem = e.target;
  // No need to fix if there is no alt text
  if (! elem.alt)
    return;
  const parent = elem.parentNode;
  parent.insertBefore(document.createTextNode(elem.alt), elem);
  parent.removeChild(elem);
}

export default function Image({id, folder, name, cls, click}) {
  const path =  '/assets/' + (folder ? folder + '/' : '') + name + '.png'
  return <img 
    id={id}
    src={path}
    alt='' 
    className={cls}
    onClick={click}   
    onError={removeBrokenIcon}
  />
}