import { useEffect } from 'react'
import { useNavigate } from "react-router-dom"
//
import CategoryBar from '../components/CatBar'
import Image from '../components/Image'
import Legends from '../components/Legends'
import { addOrRemoveClass, formatPrice, formatText, setBodyColor } from '../Utils'
import { drinks } from '../language/ItemTokens'
// css
import '../css/App.css'

export default function Menu({data}) {
  const lang = data.lang
  const showCats = data.showCats
  const category = data.category
  const cat = drinks[category]
  // Different prices for the drinks
  const prices = [ 400, 425, 450 ]

  const navigate = useNavigate()

  useEffect(() => {
    document.querySelectorAll('.category-button').forEach(btn => {
      addOrRemoveClass((btn.id === category), btn, 'selected')
    })
    setBodyColor(cat.bki)
  }, [category, cat.bki])
 
  function changeCat(e) {
    data.setCategory(e.currentTarget.id)
    data.setShowCats(false)
  }

  function selectItem(e) {
    data.setDrink({id: e.currentTarget.id})
    navigate('/item')
  }

  return (<div>
    {showCats && <CategoryBar clicked={changeCat} lang={lang} />}
    <Legends prices={prices} token='selDrinks' lang={lang} /> 
    <div className='items-grid'>
      {cat.list.data.map((item) => ( 
        <div className='items-container' key={item.id} id={item.id} onClick={selectItem}>
          <Image cls={'items-img p' + item.p} folder='drinks' name='ilt' />
          <div>{formatText(item.n, lang)}</div>
          {false  &&  <div>{formatPrice(item.p)}</div>}
        </div>
      ))}
    </div>
  </div>)

}
