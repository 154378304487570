import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//
import Banner from '../components/Banner'
import Quantity from '../components/Quantity'
import Sweetness from '../components/Sweetness'
import Temperature from '../components/Temperature'
import Toppings from '../components/Toppings'
import { formatPrice, formatText, formatToken } from '../Utils'
import { drinks } from '../language/ItemTokens'
import { toppingTokens } from '../language/ItemTokens'

export default function Item({data}) {
  const lang = data.lang
  const category = data.category
  const drink = data.drink
  const sweet = data.sweet
  const temp = data.temp
  const toppings = data.toppings
  const cart = data.cart

  const [qty, setQty] = useState(1)
  const [toppPrice, setToppPrice] = useState(0)
  const [isDisabled, setIsDisabled] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (drink.id) {
      const cat = drinks[category]
      const item = cat.list.findItem(drink.id)
      item.index = undefined
      item.name = formatText(item.n, lang)
      item.extra = (item.x ? formatToken(drink.id, lang) : undefined)
      item.price = formatPrice(item.p)
      item.sweet = (cat.noSweet || item.noSweet ? undefined : data.sweet)
      item.temp = (cat.noTemp || item.noTemp ? undefined : data.temp)
      item.toppings = toppings
      item.toppPrice = toppPrice
      item.qty = qty
      item.bk = cat.bk
      data.setDrink(item)
      console.log('Drink: ', JSON.stringify(drink))
    }
  })

  // Enable/Disable the Cart button
  useEffect(() => {
    setIsDisabled(qty <= 0)
  }, [qty])

   // Calculate the total price of the toppings
   useEffect(() => {
    //console.log('toppings = ', toppings)
    const calcPrice = ((total, id) => (total + parseInt(toppingTokens.list.findItem(id).p)))
    setToppPrice(toppings.reduce(calcPrice, 0))
  }, [toppings])

  // Add the current item to cart
  // Create the object quan in order to call the child's function
  function addToCart() {
    // This should not happen; just in case
    if (! drink.id  ||  drink.qty === 0) {
      return
    }
    if (drink.index === undefined) { // add
      drink.qty = qty;
      drink.sweet = sweet
      drink.temp = temp
      console.log('add item ', JSON.stringify(drink))
      data.setCart(cart.concat(drink))
      navigate('/cart')
    }
    else { // edit
 //     Object.assign(cart[drink.index], drink); // update the item
    }
    setQty(1)
  }

  function changeQty(q) {  
    console.log('changeQty!')
    setQty(q)
    data.setDrink(drink)
  }

  function goBack() {
    navigate(-1)
  }

  return (
    <div style={{width: '100%'}}> 
      <Banner 
        bkColor={drink.bk} 
        text={drink.name + ' ' + drink.price + (toppPrice === 0 ? '' : ' + ' + formatPrice(toppPrice))} 
        onClose={goBack}
      />
      {drink.extra && (lang === 'en') && <div>{drink.extra}</div>}
      <Toppings data={data}/>
      <div className='options-container'>
        <div className='rows-container'>
          {drink.sweet && <Sweetness data={data} />}
          {drink.temp && <Temperature data={data} />}
        </div>
        <div className='buttons-container'>
          <Quantity key='qty' qty={qty} changeQty={changeQty} />
          <div className='button-box'>
            <button className='emoji-button cart' disabled={isDisabled} onClick={addToCart}>➜🛒</button>
          </div> 
        </div>
      </div>
    </div>
  )
}

//div className='qty-cart-container'></div>
