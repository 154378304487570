import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { io } from 'socket.io-client'
//
import { toppingTokens } from '../language/ItemTokens'
import { formatPrice, formatTemp, formatText, formatToken, setBodyColor } from '../Utils'
// css
import '../css/App.css'

export default function Cart({data}) {
  const lang = data.lang
  const cart = data.cart

  const navigate = useNavigate()
  const URL = process.env.REACT_APP_SERVER
  const socket = io(URL)

  useEffect(() => {
    // Set background to the default color in case it has been changed
    setBodyColor()
  })

  // Need more work...
  useEffect(() => {
    const handleBeforeUnload = () => {
      // if (socket.connected) {
      //   socket.disconnect()
      // }
      //console.log('Running unload', socket.id, 'connected', socket.connected)
    };  
    // handles when page is unloaded
    window.addEventListener('beforeunload', handleBeforeUnload)
    // cleanup function handles when component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      handleBeforeUnload();
    }
  }, [socket])

  //const editItem = (e) => {
    // Go to the clicked item
    // const index = e.currentTarget.getAttribute('index');
    // const item = cart[index]
    // item.index = index;
    // setShowCart(false);
    // changeQty(item.qty);
  //}

  //const sound = new Audio('/assets/ding.mp3')

  function makeOrder() {
    const order = {
      phone: '510-555-1234',
    }
    order.items = cart.map((item) => {
      return {
        name: item.n[1],
        qty: item.qty,
        sweet: item.sweet,
      }
    })
    console.log('Make Order: ' + JSON.stringify(order))
    console.log(socket.id, 'connected = ', socket.connected);
    socket.timeout(1000).emit('store', order, () => {
      //sound.play()
      console.log('emitted to store')
    })
    // socket.disconnect()
    // console.log('Connected2 = ', socket.connected)
    //updateCart([])
    data.setCart([])
    gotoMenu()
  }

  function gotoMenu() {
    navigate('/menu')
  }

  const GridRowBase = (text, sign, price, bold) => (
    <div className={'grid-container' + (bold ? ' bold' : '')}>
      <div>{text}</div>
      <div>{sign}</div>
      <div>{formatPrice(price)}</div>
    </div>
  )

  const GridRow = (name, extra, sign, price, bold) => (
    GridRowBase(formatToken(name, lang) + extra, sign, price, bold)
  )

  const ToppingsRow = (item) => {
    const size = item.toppings.length
    if (size === 0) {
      return <></>
    }
    let text = ''
    for (let i = 0; i < size; i++ ) {
      let t = toppingTokens.list.findItem(item.toppings[i])
      text += formatText(t.n, lang)
      if (i < size - 1) {
        text += ' + '
      }
    }
    return GridRowBase(text, '=', item.toppPrice)
  }

  // Display empty cart text
  if (cart.length === 0) {
    return <div>{formatToken('emptyCart', lang)}</div>
  }

  // Calculate the subtotal, tax and total
  cart.forEach((item) => item.amount = item.p * item.qty)
  const total = cart.reduce(((total, item) => total + item.amount + item.toppPrice), 0)
  //const subtotal = cart.reduce(((total, item) => total + item.amount + item.toppPrice), 0)
  //const taxRate = parseFloat(process.env.REACT_APP_TAX_RATE)
  //const tax = subtotal * taxRate
  //const total = subtotal + tax

  return(<div className='cart-container'>
    {cart.map((item, i) => (<div key={'i' + i}>
    <div>
      <div className='grid-container full'>
        <div>{formatText(item.n, lang)}</div>
        <div style={{color: 'brown'}}>{item.sweet + '%'}</div>
        <div>{formatTemp(item.temp, lang)}</div>
        <div>{item.price}</div>
        <div>x</div>
        <div>{item.qty}</div>
        <div>=</div>
        <div>{formatPrice(item.amount)}</div>
      </div>
    </div>
    { ToppingsRow(item) }
    </div>))}
    { GridRow('total', '', '=', total, true) }
    <div className='options-container'>
      <div className='buttons-container' >
        <div className='button-box'>
          <button className='emoji-button menu' onClick={gotoMenu} >🥤⬅</button>
        </div>
        <div className='button-box'>
          <button className='emoji-button order' onClick={makeOrder}>⫸</button>
        </div>
      </div>
    </div>
  </div>)
}

//{ GridRow('subtotal', '', '=', subtotal) }
//{ GridRow('tax', ' (' + formatPercentage(taxRate) + ')', '+', tax) }