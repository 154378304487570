import { useState, useEffect } from 'react';
import Parser from 'html-react-parser';
//
import { tokens } from './language/Tokens'
import processLang from './language/Langs';

// The state value is saved in localStorage.
// It is used to initialize the state at start up.
export function useLocalStorageValue(defaultValue, key) {
    const [value, setValue] = useState(() => {
        const localValue = window.localStorage.getItem(key);
        return localValue ? localValue : defaultValue;
    });

    useEffect(() => {
        window.localStorage.setItem(key, value);
    }, [key, value]);

    return [value, setValue];
}

export const setBodyColor = (bkColor) => document.body.style.background = (bkColor ? bkColor : 'beige')

// 0.1025 -> '10.25%'
export const formatPercentage = (p) => (p * 100) + '%'

// 350 -> '$3.50'
export const formatPrice = (price) => '$' + (price / 100).toFixed(2)

// The item is an array of [ 'English Text', 'Traditional Text', <optional 'Simplified Text'> ]
export const formatText = (item, lang) => Parser(processLang(item, lang))

// Shorthand functions
export const formatToken = (key, lang) => formatText(tokens[key], lang)
export const formatTemp  = (temp, lang) => formatToken('temp' + temp, lang)

export function addOrRemoveClass(cond, comp, className) {
    if (cond) {
      comp.classList.add(className)
    }
    else {
      comp.classList.remove(className)
    }
  }
  
